#bio {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 80%;
  height: 100%;

  @media screen and (max-width: 640px) {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }

  .bio-image {
    width: 30%;
    border-radius: 50%;
  
    @media screen and (max-width: 640px) {
      order: 1;
      width: 60%;
      margin-bottom: 5em;
    }
  }
}


.bio-container {
  text-align: left;
  width: 60%;
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 1.25em;

  @media screen and (max-width: 640px) {
    order: 0;
    width: 80%;
    text-align: center;
    margin-bottom: 1.5em;
  }
}

.bio-text {
  color: #fff;
  font-weight: 300;
  letter-spacing: .05em;
  line-height: 1.5em;
  text-align: justify;
}