#music-page {
  width: 80%;
}

.albums {
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  width: 80%;

  @media screen and (max-width: 640px) {
    flex-direction: column;
    align-items: center;

    :last-child.album {
      margin-bottom: 5em;
    }
  }
}

.album {
  margin: 0 1em;
  position: relative;
  width: 35%;
  font-weight: 300;

  @media screen and (max-width: 640px) {
    margin: 0 0 2em;
    width: 100%;
  }
}

.album-title {
  white-space: nowrap;
  font-size: 1.25em;
  letter-spacing: .1em;
  font-weight: 300;
  text-align: left;
}

.album-container {
  position: relative;

  .album-img {
    width: 100%;
  }
}


.album-hover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 98%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: .5s;

  &:hover {
    background: rgba(255,255,255,.25);
    opacity: 1;
  }
}

.service-link {
  width: 25%;
  margin: 0 1em;
}

.service-icon {
  width: 100%;
  transition: .15s;
}

.service-icon:hover {
  margin-bottom: 8px;
}