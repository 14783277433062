.nanum {
  font-family: 'Nanum Gothic', sans-serif;
}

.quicksand {
  font-family: 'Quicksand', sans-serif;
}

.roboto {
  font-family: 'Roboto', sans-serif;
}
