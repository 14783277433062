@import 'src/styles/temp';
@import 'src/styles/fonts';
@import 'src/styles/header';
@import 'src/styles/nav';
@import 'src/styles/music';
@import 'src/styles/film';
@import 'src/styles/bio';
@import 'src/styles/connect';
@import 'src/styles/latest';

/* Colors */
$White: #ffffff;
$LightGray: #abadbd;
$SlateGray: #2d2d40;
$SlateBlue: #1f244d;
$DeepBlue: #080e3a;

html {
  margin: 0;
  padding: 0;
}

body {
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  overflow: hidden;
  margin: 0;
  padding: 0;
  background: #000;
  text-align: center;
  color: #FFF;

  @media screen and (max-width: 1020px) {
    font-size: 1.57vw;
  }

  @media screen and (max-width: 640px) {
    font-size: 3.5vw;
  }
}

#main {
  width: 100%;
  z-index: 100;
}

a {
  text-decoration: none;
  color: inherit;
}

.mobile-only {
  display: none;

  @media screen and (max-width: 640px) {
    display: inherit;
  }
}

.desktop-only {
  @media screen and (max-width: 640px) {
    display: none;
  }
}

.block {
  display: block;
}

#bg-canvas{
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.selected-content {
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  position: relative;
  color: #FFF;
  transition: 1s;
  margin: 0 auto;
  max-width: 1200px;
  max-height: calc(100vh - 25vh);
  overflow-y: scroll;
  mask-image: linear-gradient(to top, black 98%, transparent 100%);
  -webkit-mask-image: linear-gradient(to top, black 98%, transparent 100%);
  padding: 2% 0 0;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  @media screen and (max-height: 875px) {
    max-height: calc(100vh - 50vh);
    mask-image: linear-gradient(to bottom, transparent 0%, black 2%, black 98%, transparent 100%);
    -webkit-mask-image: linear-gradient(to bottom, transparent 0%, black 2%, black 98%, transparent 100%);
  }

  @media screen and (max-width: 640px) {
    margin: 14em 0 0;
  }
}

.selected-content.safari {
  @media screen and (min-width: 640px) {
    margin-top: 15em;
  }
}

.footer-space {
  min-height: 4em;
}

.wait-to-show {
  opacity: 0;
  animation: wait .75s ease-in 5s 1 normal forwards;
}

@keyframes wait {
  from {opacity: 0;}
  to {opacity: 1;}
}

.load-content {
  opacity: 0;
  animation: wait .25s ease-in 0s 1 normal forwards;
}
