.nav-container {
  margin: 7em auto 7em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;

  @media screen and (max-width: 640px) {
    margin: 5em 0 3em;
    font-size: 1.25em;
  }
}

.nav-back-container {
  position: absolute;
  bottom: .75em;
  opacity: 0;
  transition-delay: 1s;
  transition: .5s;
  cursor: pointer;
}

.nav-back-container.active {
  opacity: 1;
}

.nav-back {
  display: inline-block;
  position: absolute;
  right: 5em;
  top: .15em;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 20px solid #FFF;

  @media screen and (max-width: 640px) {
    right: 4.75em;
    top: .4em;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-right: 15px solid #FFF;
  }
}

.nav-back-text {
  font-family: 'Quicksand', sans-serif;
  color: #fff;
  font-weight: 300;
  letter-spacing: .15em;
  font-size: 1.25em;
}

.navlink-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: .5s;
}

.nav-container h2 {
  font-family: 'Quicksand', sans-serif;
  color: #fff;
  font-weight: 300;
  letter-spacing: .15em;
  font-size: 1.25em;
  cursor: pointer;
  margin-bottom: .5em;
}

.music-hover-line {
  min-width: 0em;
  max-width: 0em;
  background: #fff;
  height: 2px;
  transition: .5s;
}

.music-nav-hovered {
  min-width: 5.15em !important;
}

.music-nav-active {
  min-width: 5.15em !important;
}

.film-hover-line {
  min-width: 0em;
  max-width: 0em;
  background: #fff;
  height: 2px;
  transition: .5s;
}

.film-nav-hovered {
  min-width: 4em !important;
}

.film-nav-active {
  min-width: 4em !important;
}

.bio-hover-line {
  min-width: 0em;
  background: #fff;
  height: 2px;
  transition: .5s;
}

.bio-nav-hovered {
  min-width: 3em !important;
}

.bio-nav-active {
  min-width: 3em !important;
}

.connect-hover-line {
  min-width: 0em;
  background: #fff;
  height: 2px;
  transition: .5s;
}

.connect-nav-hovered {
  min-width: 7.4em !important;
}

.connect-nav-active {
  min-width: 7.4em !important;
}

.latest-hover-line {
  min-width: 0em;
  background: #fff;
  height: 2px;
  transition: .5s;
}

.latest-nav-hovered {
  min-width: 6em !important;
}

.latest-nav-active {
  min-width: 6em !important;
}