.social-icon-container {
  margin: 1.5em 0;
}

.email-text {
  font-size: 1.25em;
  font-weight: 300;
  letter-spacing: .1em;
  margin-bottom: 3em;
}

.social-icons {
  width: 100%;
}


.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  left: .5em;
}

.icon-img-container {
  display: flex;
  justify-content: flex-end;

  .social-icon-img {
    filter: invert(100%);
    height: 3em;
    position: relative;
    bottom: 0;
    transition: .2s;
  }

  &.hovered {
    .social-icon-img {
      bottom: 4px;
    }
  }
}

.social-icon-text {
  font-weight: 300;
  width: 70%;
  font-size: 1.25em;
  letter-spacing: .1em;
  margin-left: 1em;
  text-align: left;
}