#film {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  height: 100%;
  margin: 0 0 25%;

  .film-img-container{
    width: 45%;
    margin: 0 auto;
    transition: .5s;
    position: relative;
    cursor: pointer;
    color: #FFF;
    font-weight: bold;
    letter-spacing: .05em;
    line-height: 1.5em;
    font-weight: 200;

    .film-img {
      width: 100%;
    }

    @media screen and (max-width: 640px) {
      width: 80%;
    }
  }

}

#video-player {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .video-container {
    z-index: 1000;
    opacity: 0;
    transition: .5s;
    transition-delay: .25s;
    width: 100%;

    &.player-ready {
      opacity: 1;
    }
  }

  .close-video-player {
    z-index: 1000;
    cursor: pointer;
    font-weight: 300;
    letter-spacing: .15em;
  }

  .player {
    margin: 0 auto;

    @media screen and (max-width: 640px) {
      width: 90% !important;
      height: 300px !important;
    }
  }
}

#close-player-bg {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 999;
  background: rgba(0, 0, 0, 0.95);
}