#latest {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 80%;
  height: 100%;

  @media screen and (max-width: 640px) {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }

  .latest-text {
    color: #fff;
    font-size: 1.25em;
    font-weight: 300;
    letter-spacing: .1em;
    line-height: 1.5em;
    width: 80%;
    margin: 0 auto;
  }

  .latest-img {
    width: 70%;
    border-radius: 5px;

    @media screen and (max-width: 640px) {
      width: 100%;
    }
  }

  .latest-img-desc {
    display: block;
    margin: .5em auto 0;
    width: 75%;
    text-align: center;

    @media screen and (max-width: 640px) {
      width: 90%;
    }
  }
}