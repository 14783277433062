header h1 {
  color: #fff;
  letter-spacing: 1em;
  font-family: 'Quicksand', sans-serif;
  font-weight: 500;
  line-height: 1.5em;
  position: relative;
  margin: 3em 0 0;
  left: .5em;
  text-align: center;
  width: 100%;

  @media screen and (max-width: 640px) {
    margin: 1em 0 0;
  }
}