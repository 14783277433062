header#temp {
  z-index: 10000;
  background: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 50%;
  left: 50%;
  -ms-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);

  &.loaded {
    animation: welcome 1.5s ease-in 2s 1 normal forwards;
  }

  h1 {
    color: #fff;
    letter-spacing: .75em;
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
    margin: 0 0 1em -.25em;

    @media screen and (max-width: 640px) {
      font-size: 1.4em;
    }
  }

  img {
    height: 300px;
    animation: grow 3.25s infinite linear;
  }

  @keyframes grow {
    0% { transform: scale(1); }
    50% { transform: scale(1.075); }
    100% { transform: scale(1); }
  }

  @keyframes welcome {
    from {opacity: 1;}
    to {opacity: 0; z-index: -1;}
  }

}